import React from "react";
import { Link, graphql } from "gatsby";
import Bio from "../components/bio";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import PageTitle from "../components/page_title";

const SecondaryPost = (post: any) => {
  const title = post.frontmatter.title || post.fields.slug

  return (
    <article
      className="mb-4"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <div className="md:shrink-0 mb-2">
          <Link to={`/${post.fields.slug}`} itemProp="url" className="h-64 w-full object-cover">
            <GatsbyImage
              image={post.frontmatter.image.childImageSharp.gatsbyImageData}
              alt={`${title} - demogar blog post image`}
              className="h-64 w-full object-cover hover:brightness-100 brightness-75 transition-all"
            />
          </Link>
        </div>
        <h2 className="text-md font-semibold mr-2 px-4">
          <Link to={`/${post.fields.slug}`} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small className="text-xs font-thin px-4">{post.fields.date}</small>
      </header>
      <section className="text-sm px-4">
        <p
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
          itemProp="description"
        />
      </section>
    </article>
  );
};

const RestOfPosts = (post: any) => {
  const title = post.frontmatter.title || post.fields.slug;

  return (
    <article
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <h2 className="text-sm">
          <Link to={`/${post.fields.slug}`} itemProp="url" className="text-primary">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small className="text-secondary text-xs font-thin">{post.fields.date}</small>
      </header>
    </article>
  );
};

const BlogIndex = ({ data }: { data: any }) => {
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout>
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    );
  }

  return (
    <Layout>
      <header>
        <div className="mb-8">
          <PageTitle>Blog</PageTitle>
          <h2 className="text-lg text-text-secondary">
            Escribo sobre código, diseño, tecnología y otras cosas que roben mi atención. Todos los artículos están en español.
          </h2>
        </div>
      </header>

      <section>
        <div>
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-8 border-b-2 pb-8 mb-4 border-border">
            {posts.slice(0, 9).map((post: any) => {
              return (
                <div key={post.fields.slug} className="bg-surface rounded-lg overflow-hidden shadow-lg flex flex-col h-full transition-transform duration-300 hover:scale-105">
                  <SecondaryPost {...post} />
                </div>
              )
            })}
          </div>

          {posts.slice(9, posts.length - 1).map((post: any) => {
            return (
              <div key={post.fields.slug} className="border-b-2 mb-2 pb-2 border-border">
                <RestOfPosts {...post} />
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

export const Head = () => <Seo title="Blog" />;

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
          date(formatString: "LL", locale: "es")
        }
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
              gatsbyImageData(quality: 95, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
